import { l as lodash } from './lodash-8698f694.js';

/**
 * A data model with simple observable hook which can be consumed by Stencil components or pure JS routine.
 */
class MoModel {
    constructor(data, config) {
        this.data = data;
        this.config = config || {};
        this.onChanges = lodash.compact([this.config.onChange]);
    }
    /**
     * == Public methods =============================================================================
     */
    /**
     * Provides the snapshot of current model states.
     */
    get meta() {
        return this.getMeta();
    }
    getMeta() {
        return this.cloneProps(['config', 'data']);
    }
    subscribe(callback, component) {
        const unSubscribe = () => lodash.pull(this.onChanges, callback);
        this.onChanges.push(callback);
        this.notifyUpdatesOnSubscribe(callback);
        if (component) {
            component.disconnectedCallback = lodash.flow(component.disconnectedCallback || lodash.noop, unSubscribe || lodash.noop);
        }
        return unSubscribe;
    }
    /**
     * == Protected and private methods ==============================================================
     */
    /**
     * For supporting advanced data transformation in subclasses.
     */
    getNotificationPayload() {
        return this.data;
    }
    notifyUpdates(meta) {
        this.notifySubscribers(meta);
    }
    notifySubscribers(meta, callbacks) {
        const data = this.getNotificationPayload();
        const cloneNotificationPayload = this.config.cloneNotificationPayload !== false;
        const payload = cloneNotificationPayload ? lodash.cloneDeep(data) : data;
        meta = lodash.mapValues(meta, (value, key) => (key === 'item') ? lodash.cloneDeep(value) : value);
        callbacks !== null && callbacks !== void 0 ? callbacks : (callbacks = this.onChanges);
        lodash.each(callbacks, (callback) => callback(payload, meta));
    }
    /**
     * Notify subscriber callback during initial subscription.
     *
     * For supporting custom handling in subclasses - either disable initial notification, or overload
     * with the custom routine.
     */
    notifyUpdatesOnSubscribe(callback) {
        this.notifySubscribers({ action: 'subscribe' }, [callback]);
    }
    cloneProps(propList) {
        const props = lodash(this).pick(propList).mapValues(lodash.cloneDeep).value();
        return Object.assign({}, props);
    }
}

/**
 * A wrapper of data list which provides basic CRUD interface as well as observable hook inherits from MoModel.
 */
class MoBaseStore extends MoModel {
    constructor(data, config) {
        super([], config);
        this.data = data;
        this.config = config || {};
        this.idProp = this.config.idProp || 'id';
        this.onChanges = lodash.compact([this.config.onChange]);
    }
    /**
     * == Public methods =============================================================================
     */
    getItem(predicate) {
        const matcher = lodash.isString(predicate) ? { [this.idProp]: predicate } : predicate;
        return lodash.find(this.data, matcher);
    }
    addItem(item) {
        this.data = [...(this.data || []), item];
        this.notifyUpdates({ action: 'add', item });
    }
    updateItem(item, index) {
        const itemIndex = index !== null && index !== void 0 ? index : this.getItemIndex(item);
        this.data[itemIndex] = item;
        this.notifyUpdates({ action: 'update', item });
    }
    removeItem(item, index) {
        const itemIndex = index !== null && index !== void 0 ? index : this.getItemIndex(item);
        lodash.pullAt(this.data, itemIndex);
        this.notifyUpdates({ action: 'remove', item });
    }
    /**
     * == Protected and private methods ==============================================================
     */
    /**
     * For supporting advanced data transformation in subclasses.
     */
    getItemIndex(item) {
        return lodash.findIndex(this.data, { [this.idProp]: item[this.idProp] });
    }
}

export { MoBaseStore as M, MoModel as a };
